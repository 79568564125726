//トップページ　キービジュアルスライダー
export function aboutFunc() {
	let aboutTop = $('#aboutTop').length;
  
	if (aboutTop) {
			//背景色の変更
		var secArr = new Array();
		var current = -1;
		/* ここに背景色を順に記述する*/
		//var secColor = new Array('rgba(247, 245, 244,.8)', 'rgba(239, 235, 233,.8)','rgba(247, 245, 244,.8)', 'rgba(239, 235, 233,.8)','rgba(247, 245, 244,.8)');
		//var secColor = new Array('rgba(247, 245, 244,.8)', 'rgba(239, 235, 233,.8)', 'rgba(239, 235, 233,.8)','rgba(247, 245, 244,.8)');
		var secColor = new Array('rgba(232, 223, 224,.8)', 'rgba(248, 240, 241,.8)', 'rgba(248, 240, 241,.8)','rgba(248, 240, 241,.8)','rgba(248, 240, 241,.8)','rgba(248, 240, 241,.8)');
				
		function chengeBG(secNum) {
		  if (secNum != current) {
			  current = secNum;
			  $('.aboutIrohaArea').css({
				  backgroundColor: secColor[current]
			  });
		  }
		}
		$(window).on('scroll', function () {
		  var scroll = $(window).scrollTop();
		  let winHight = window.innerHeight;
		
		  $('.gradation').each(function (i) {
			  secArr[i] = $(this).offset().top;
		  });
				
		  for (var i = secArr.length - 1; i >= 0; i--) {
			if (scroll > secArr[i] - winHight) {
			  chengeBG(i);
			  break;
			}
		  }
		});
	}
  }