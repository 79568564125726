//トップページ　キービジュアルスライダー
export function idxFunc() {
  let indexTop = $('#indexTop').length;
  let videoControl = $('#videoControl').length;

  if (indexTop) {
    //背景色の変更
    var secArr = new Array();
    var current = -1;
    /* ここに背景色を順に記述する*/
    var secColor = new Array('rgba(247, 245, 244,.8)', 'rgba(245, 252, 245,.8)', 'rgba(255, 249, 243,.8)', 'rgba(242, 252, 253,.8)', 'rgba(251, 242, 248,.8)', 'rgba(242, 242, 249,.8)', 'rgba(255, 250, 240,.8)', 'rgba(242, 247, 247,.8)');

    function chengeBG(secNum) {
      if (secNum != current) {
          current = secNum;
          $('.indexArea').css({
              backgroundColor: secColor[current]
          });
          // $('.btn01').css({
          //   backgroundColor: secColor[current]
          // });
      }
    }
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      let winHight = window.innerHeight;
    
      $('.gradation').each(function (i) {
          secArr[i] = $(this).offset().top;
      });

      for (var i = secArr.length - 1; i >= 0; i--) {
        if (scroll > secArr[i] - winHight) {
          chengeBG(i);
          break;
        }
      }
    });

		let resizecheck = $('#responsibleCheck').width();
	  
		window.addEventListener('resize', () => {
		  resizecheck = $('#responsibleCheck').width();
	  
		  //アコーディオン初期化
			newsSlideFunc();
		}, false);

    function mainVisualSlideFunc(){
      $("#mainVisualSlide").slick({
          loop: true,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 3000,
          dots:true,
          dotsClass: "mainVisualDots",
          prevArrow: '<img src="/images/common/mainview_back_arrow.svg" class="slide-arrow prev-arrow">',
          nextArrow: '<img src="/images/common/mainview_next_arrow.svg" class="slide-arrow next-arrow">',
          fade: true,
          infinite: true,
      });
    }mainVisualSlideFunc();

    function pickUpSlideFunc(){
      $("#pickUpSlide").slick({
        autoplay: true,
        dots:true,
        dotsClass: "slide-dots",
        slidesToShow:3,
        autoplaySpeed: 5000,
        speed: 800,
        prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
        nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
        responsive:[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode:true,
              centerPadding:"16.26%",
              },
          },
        ],
      });
    }pickUpSlideFunc();

		function newsSlideFunc(){
			if (resizecheck == '2') {
				$("#newsSlide").not('.slick-initialized').slick({
					autoplay: false,
					autoplaySpeed: 10000,
					speed: 800,
					centerMode:true,
					centerPadding:"10%",
					prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
					nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
				});
			  }else{
				$("#newsSlide.slick-initialized").slick('unslick');
			  }
		}newsSlideFunc();

  }

  function playVideos(videoWrappers) {
      const startPosition = window.pageYOffset + window.innerHeight;
      for(let i = 0; i < videoWrappers.length; i++) {
          const videoPosition = videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
          if(startPosition > videoPosition) {
              const video = videoWrappers[i].getElementsByTagName('video');
              video[0].play();
          }
      }
  }
  const videoWrappers = document.getElementsByClassName('videoControl');
  if(videoWrappers.length) {
      playVideos(videoWrappers);
      window.addEventListener('scroll', function() {
          playVideos(videoWrappers);
      }, false);
  }

  let btn01 = $(".btn01").length;
  if(btn01){
    $(".btn01").hover(
      function() {
        if($(".btn01").hasClass("circleIn")){
          $(this).removeClass("circleIn");
        }
      }
  );
  }
}