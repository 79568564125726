//トップページ　キービジュアルスライダー
export function productsFunc() {
	let productsTop = $('#productsTop').length;
	let imgSlide = $('.imgSlide').length;
	let productsMainSlide = $('.productsMainSlide').length;
	let productsSingle = $('#productsSingleTop').length;
	let productsSingleMovie = $('#movie').length;
  
	if (productsTop) {
		let searchHeight = $("#search").offset().top;
		console.log(searchHeight);
		
		$(window).on('scroll', function () {
			let scrollHeight = $(window).scrollTop();

			if(searchHeight <= scrollHeight){
				$(".followingBtn").fadeIn()
			}
		});
	}

	if (productsSingle) {
		    //背景色の変更
			var secArr = new Array();
			var current = -1;
			/* ここに背景色を順に記述する*/
			var secColor = new Array('rgba(248,248,248,.8)', 'rgba(245,245,245,.8)', 'rgba(248,248,248,.8)', 'rgba(245,245,245,.8)','rgba(248,248,248,.8)', 'rgba(245,245,245,.8)','rgba(248,248,248,.8)', 'rgba(245,245,245,.8)');
		
			function chengeBG(secNum) {
			  if (secNum != current) {
				  current = secNum;
				  $('.productsArea').css({
					  backgroundColor: secColor[current]
				  });
			  }
			}
			$(window).on('scroll', function () {
			  var scroll = $(window).scrollTop();
			  let winHight = window.innerHeight;
			
			  $('.gradation').each(function (i) {
				  secArr[i] = $(this).offset().top;
			  });
		
			  for (var i = secArr.length - 1; i >= 0; i--) {
				if (scroll > secArr[i] - winHight) {
				  chengeBG(i);
				  break;
				}
			  }
			});

			let resizecheck = $('#responsibleCheck').width();
			let specAccordionBtn = $("#openBtn");
			let specAccordionTarget = $("#specDateList");
		
			window.addEventListener('resize', () => {
			  resizecheck = $('#responsibleCheck').width();
		
			  //アコーディオン初期化
			  if (resizecheck == '2') {

			  } else if (resizecheck == '1') {
				specAccordionTarget.css('display','flex');
			  }
			}, false);
		
			//アコーディオン初期化
			if (resizecheck == '2') {
			  specAccordionTarget.css('display','none');
			} else if (resizecheck == '1') {
			  specAccordionTarget.css('display','flex');
			}
  
			  specAccordionBtn.on('click', function () {
				  $(this).toggleClass('isOpen');
				  specAccordionTarget.stop().slideToggle(500);

				  if(specAccordionBtn.hasClass("isOpen")){
					specAccordionBtn.text("Close")
					}else{
						specAccordionBtn.text("Open")
					}
			});

			// const agent = window.navigator.userAgent.toLowerCase()
			// if (agent.indexOf("safari") != -1) {
			// 	let video = document.getElementById('video');
					
			// 	video.play(); //自動再生
			// }

			function productsLineupItem(){
				let showClass = $('#lineup .productsLineupList').length;

				if(showClass == 1 || showClass == 2){
					$('.productsLineupListWrap').addClass('center');
				};
				if(showClass == 4){
					$('.productsLineupListWrap').addClass('twoColumn');
				};
			}
			productsLineupItem();
	}


	if (productsMainSlide) {
		$(".productsMainSlide").slick({
			autoplay: false,
			autoplaySpeed: 10000,
			speed: 800,
			infinite: false,
			prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
			nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
			asNavFor: ".productsSubSlide",
		});

		$(".productsSubSlide").slick({
			slidesToShow: 7,
			autoplay: false,
			autoplaySpeed: 10000,
			speed: 800,
			asNavFor: ".productsMainSlide",
			focusOnSelect: true,
			arrows: false,
			infinite: false,
			responsive: [
				{
				  breakpoint: 768,
				  settings: {
					slidesToShow: 3,
					arrows: true,
					prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
					nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
				  },
				},
			  ],
		});
	}

	if (imgSlide) {
		$(".imgSlide").slick({
			autoplay: false,
			autoplaySpeed: 10000,
			speed: 800,
			prevArrow: '<img src="/images/common/slide_prev.svg" class="slide-arrow prev-arrow">',
			nextArrow: '<img src="/images/common/slide_next.svg" class="slide-arrow next-arrow">'
		});

		$(".toggleBtn").on('click', function () {
			$(this).toggleClass('open');
			$(this).next().stop().slideToggle(500);
			if($(this).hasClass("open")){
			  $(this).text("More");
			  }else{
				  $(this).text("Close");
			  }
		  });
	}
  }

	export function ajaxFunc() {
		let productsTop = $('#productsTop').length;

		if (productsTop) {
			let getUrl = location.host;
			var wpAjaxUrl;
			
			if(getUrl == 'iroha-brandsite-renewal.test'){
				wpAjaxUrl = 'http://iroha-brandsite-renewal.test/wp/wp-admin/admin-ajax.php';
			}else if(getUrl == 'localhost:3000'){
				wpAjaxUrl = 'http://localhost:3000/wp/wp-admin/admin-ajax.php';
			}else if(getUrl == 'iroha-tenga.com'){
				wpAjaxUrl = 'https://iroha-tenga.com/wp/wp-admin/admin-ajax.php';
			}else if(getUrl == 'dev-iroha.acceptee-tenga.com'){
				wpAjaxUrl = 'http://dev-iroha.acceptee-tenga.com/wp/wp-admin/admin-ajax.php';
			}

			let tagClass = $('.productsSearchList li');
			tagClass.on('click', function () {
				tagClass.removeClass('sort');
				$(this).addClass('sort');
			});
			
			var postData = {
				'action': 'view_products',
			};

			function ajax_function(postData) {
				return $.ajax({
					type:'POST',
					url: wpAjaxUrl,
					method: 'post',
					dataType: 'html',
					data: postData,
				})
			}
			
			// Ajaxで送信
			ajax_function(postData).done(function (data) {
					$('#productsList').html(data);
			}).fail(function (xhr, textStatus, errorThrown) {
				console.log("NG:" + xhr.status);
			});

			let jsAjaxAll = $('.productsSearchList .all');
			jsAjaxAll.on('click', function () {
				ajax_function(postData).done(function (data) {
					$('#productsList').html(data);
				}).fail(function (xhr, textStatus, errorThrown) {
					console.log("NG:" + xhr.status);
				});
			});


			let jsAjax = $('.productsSearchList .tag');
			jsAjax.on('click', function () {
				let terms = this.dataset.name;

				// Ajaxで送信
				$.ajax({
					type:'POST',
					url: wpAjaxUrl,
					method: 'post',
					dataType: 'html',
					data: {
						'action': 'view_term_products',
						'terms': terms,
					},
				}).done(function (data) {
					$('#productsList').html(data);
				}).fail(function (xhr, textStatus, errorThrown) {
					console.log("NG:" + xhr.status);
				});
			});
		}
	}