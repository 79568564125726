export function buttonActionFunc() {
  let resizecheck = $('#responsibleCheck').width();
  let accordionBtn = $('.aCd');

  window.addEventListener('resize', () => {
    resizecheck = $('#responsibleCheck').width();

    //アコーディオン初期化
    if (resizecheck == '2') {
      // accordionBtn.next('dd').css('display','none');
    } else if (resizecheck == '1') {
      accordionBtn.removeClass('isOpen');
      accordionBtn.next('dd').css('display','block');
    }
  }, false);

  //アコーディオン初期化
  if (resizecheck == '2') {
    accordionBtn.next('dd').css('display','none');
  } else if (resizecheck == '1') {
    accordionBtn.removeClass('isOpen');
    accordionBtn.next('dd').css('display','block');
  }

  //アコーディオン
  accordionBtn.click(function(){
    if (resizecheck == '2') {
      if ($(this).hasClass('isOpen')) {
        $(this).removeClass('isOpen');
        $(this).next('dd').stop().css('height','auto').slideUp(500);
      }
      else {
        $(this).addClass('isOpen');
        $(this).next('dd').stop().css('height','auto').slideDown(500);
      }
    }
  });

  //言語選択
  $('#jsLang').find('dt').on('click', function () {
    $(this).toggleClass('open');
    $(this).next().stop().slideToggle(500);
  });

  // サブナビ
  let subNav = $('#subNav').length;
  if (subNav) {
		let resizecheck = $('#responsibleCheck').width();
		let accordionBtn = $('#accordionBtn');
    let scrollTarget = $("#subNav .list a");
    let arrowTarget = $("#accordionBtn");
    let bgTarget = $("#subNav dd");
	
		window.addEventListener('resize', () => {
		  resizecheck = $('#responsibleCheck').width();
	
		  //アコーディオン初期化
		  if (resizecheck == '2') {
			accordionBtn.next('dd').css('display','none');
      scrollTarget.on("click",function(){
        if(arrowTarget.hasClass("isOpen")){
          arrowTarget.removeClass("isOpen")
        }
        bgTarget.css({
          "display":"none"
        })
      });

		  } else if (resizecheck == '1') {
			accordionBtn.removeClass('isOpen');
			accordionBtn.next('dd').css('display','block');

      scrollTarget.on("click",function(){
        if(arrowTarget.hasClass("isOpen")){
          arrowTarget.removeClass("isOpen")
        }
  
        bgTarget.css({
          "display":"block"
        })
      });

		  }
		}, false);
	
		//アコーディオン初期化
		if (resizecheck == '2') {
		  accordionBtn.next('dd').css('display','none');
      scrollTarget.on("click",function(){
        if(arrowTarget.hasClass("isOpen")){
          arrowTarget.removeClass("isOpen")
        }
  
        bgTarget.css({
          "display":"none"
        })
      });
		} else if (resizecheck == '1') {
		  accordionBtn.removeClass('isOpen');
		  accordionBtn.next('dd').css('display','block');
      scrollTarget.on("click",function(){
        if(arrowTarget.hasClass("isOpen")){
          arrowTarget.removeClass("isOpen")
        }
  
        bgTarget.css({
          "display":"block"
        })
      });
		}

		accordionBtn.on('click', function () {
			$(this).toggleClass('isOpen');
			$(this).next().stop().slideToggle(500);
		});

    // if (resizecheck == '2'){
    //   let scrollTarget = $("#subNav .list a");
    //   let arrowTarget = $("#accordionBtn");
    //   let bgTarget = $("#subNav dd");
    //   scrollTarget.on("click",function(){
    //     if(arrowTarget.hasClass("isOpen")){
    //       arrowTarget.removeClass("isOpen")
    //     }
  
    //     bgTarget.css({
    //       "display":"none"
    //     })
    //   });
    // }
	}


  let pageNateItemCount = $('ul.custom li.page').length;
  let pageNateItem = $('ul.custom li.page');
  if(pageNateItemCount > 5){
    let pageNateNum = $('ul.custom li.page.active').find('a').text() - 1;
    let pageNateAllNum = pageNateItemCount - 1;

    console.log(pageNateNum);
    console.log(pageNateAllNum);
    
    if(pageNateNum == 0){
        pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
    } else if(pageNateNum == 1){
        pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
    } else if(pageNateNum == 2){
        pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
        pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    }  else if(pageNateNum == 3){
      pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
      pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    } else if(pageNateNum == pageNateAllNum){
        pageNateItem.slice(0, pageNateNum - 4).css('display', 'none');
    } else if(pageNateNum == pageNateAllNum - 1){
       pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
       pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
    }  else if(pageNateNum == pageNateAllNum - 2){
      pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
      pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
   } else {
       pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
       pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    }
  }

  //戻るボタン
  var ans; //1つ前のページが同一ドメインかどうか
  var bs  = false; //unloadイベントが発生したかどうか
  var ref = document.referrer;
  $(window).bind("unload beforeunload",function(){
      bs = true;
  });
  var re = new RegExp(location.hostname,"i");
  if(ref.match(re)){
      ans = true;
  }else{
      ans = false;
  }

  var regexp1 = new RegExp("/news/");
  var regexp2 = new RegExp("/$");
  var regexp3 = new RegExp("/shop/iroha_store/$");
  var regexp4 = new RegExp("/media/");
  //パラメーターの取得
  var param = location.search;

  $('#newsToListBtn a').bind("click",function(){
    var that = this;
    if(ans){

      if(param == "?cat=news"){
        location.href = $(that).attr("href");
      } else if(ref.match(regexp1)){
        history.back();
      } else if(ref.match(regexp3)){
        location.href = $(that).attr("href");
      } else if(ref.match(regexp2)){
        location.href = $(that).attr("href");
      } else{
        history.back();
      }

      setTimeout(function(){
        if(!bs){
          location.href = $(that).attr("href");
        }
      },100);
    }else{
      location.href = $(this).attr("href");
    }
    return false;
  });
  $('#mediaToListBtn a').bind("click",function(){
    var that = this;
    if(ans){

      if(param == "?cat=media"){
        location.href = $(that).attr("href");
      } else if(ref.match(regexp4)){
        history.back();
      } else if(ref.match(regexp3)){
        location.href = $(that).attr("href");
      } else if(ref.match(regexp2)){
        location.href = $(that).attr("href");
      } else{
        history.back();
      }

      setTimeout(function(){
        if(!bs){
          location.href = $(that).attr("href");
        }
      },100);
    }else{
      location.href = $(this).attr("href");
    }
    return false;
  });
  
  $("#faqListTab .tab").eq(0).addClass("active");
  $("#faqList .faqListBox").eq(0).addClass("active");
  
  $('#faqListTab').on('click', '.tab', function () {
    let article = $(this).index();
    
    $(".faqListBox").removeClass("active");
    $(".faqListBox").eq(article).addClass("active");

    $('#faqListTab li').removeClass('active');
    $(this).addClass('active');
    
    return false;
  });
}
export function lagActionFunc() {
  let resizecheck = $('#responsibleCheck').width();

  window.addEventListener('resize', () => {
    resizecheck = $('#responsibleCheck').width();
  }, false);

  //マウスを乗せたら発動
  $('#jsLang').hover(function () {
    if (resizecheck == '1') {
      $(this).find('dt').addClass('open');
      $(this).find('dd').stop().slideDown(500);
    }
    //ここにはマウスを離したときの動作を記述
  }, function () {
    if (resizecheck == '1') {
      $(this).find('dt').removeClass('open');
      $(this).find('dd').stop().slideUp(500);
    }
  });
}