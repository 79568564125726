import objectFitImages from 'object-fit-images';
import {
  jqueryCookie,
  observeFunc,
  waitFunc,
  fadeInFunc,
  sticyFunc,
  movieSwitchFunc,
  lazyImagesFunc,
} from "./common/_common";

import {
  headerNavFunc,
} from"./common/_header"
import {
  footerFunc,
} from"./common/_footer"
import {
  buttonActionFunc,
  lagActionFunc
} from "./common/_buttonAction";
import{
  pageLinkAnimationFunc,
  pageMoveAnkerFunc,
} from"./common/_pageLinkAnimation"
import{
  animeLinkFunc,
} from"./common/_hoverFunc"

import{
  idxFunc,
} from"./pages/_index"
import{
  contentsFunc,
} from"./pages/_contents"
import{
  productsFunc,
  ajaxFunc,
} from"./pages/_products"
import{
  shopFunc,
} from"./pages/_shop"
import{
  newsFunc,
} from"./pages/_news"
import {
  contactFunc
} from "./pages/_contact";
import {
  faqFunc
} from "./pages/_faq";
import{
  aboutFunc,
} from"./pages/_about"
import {
  mizuharaFunc,
  keyVisualMovie
} from "./pages/_mizuhara";
import{
	heightLine,
	heightLineSetFunc,
	heightLineFunc,
} from"./common/_heightLine";
import{
  anniversaryFunc,
}from"./pages/_anniversary"
//* ---------------------------------------
//通常実行
//-------------------------------------- */
let setTimer = false;
footerFunc();
//* ----------------------------------------
//window実行
//--------------------------------------- */
$(function(){
  $('#click_yes').on('click', function() {
    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    $.cookie('agreed', 'true', { path: '/', expires:date, domain:'iroha-tenga.com', secure:true});
  });

  setTimeout(function() {
    if (document.getElementsByTagName("html")[0].classList.contains('wf-active') != true) {
        document.getElementsByTagName("html")[0].classList.add('loading-delay');
    }
  }, 3000);
});

////// ロード時
$(window).on('load', function () {
  jqueryCookie();
  fadeInFunc();
  sticyFunc();
  headerNavFunc();
  buttonActionFunc();
  lagActionFunc();
  pageLinkAnimationFunc();
  pageMoveAnkerFunc();
  animeLinkFunc();
  keyVisualMovie();
  
  idxFunc();
  contentsFunc();
  productsFunc();
  ajaxFunc();
  shopFunc();
  newsFunc();
  contactFunc();
  faqFunc();
  aboutFunc();
  anniversaryFunc();
  mizuharaFunc();
  movieSwitchFunc();
  heightLine();
	heightLineSetFunc();
  lazyImagesFunc();
});

// リサイズ時
/*リサイズ後,200msで発火する設定*/
$(window).on('resize', function () {

  //高さ調整
  let heightAdjustment = function(){
    heightLine();
    heightLineFunc();
  }
  setTimeout(heightAdjustment, 300);

  if (setTimer !== false) {
    clearTimeout(setTimer);
  }
  setTimer = setTimeout(() => {
    //処理
  }, 200);
});

// スクロール時
$(window).on('scroll', function () {
  if (setTimer !== false) {
    clearTimeout(setTimer);
  }
  setTimer = setTimeout(() => {
    //処理
  }, 200);
});
///* -----------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//----------------------------------------------- */
var uaFlag = 0;
if ((
    navigator.userAgent.indexOf('iPhone') > 0) ||
  (navigator.userAgent.indexOf('iPod') > 0) ||
  (navigator.userAgent.indexOf('iPad') > 0) ||
  (navigator.userAgent.indexOf('Android') > 0) ||
  (navigator.userAgent.indexOf('BlackBerry') > 0) ||
  (navigator.userAgent.indexOf('PlayBook') > 0) ||
  (navigator.userAgent.indexOf('Kindle') > 0) ||
  (navigator.userAgent.indexOf('Silk') > 0) ||
  (navigator.userAgent.indexOf('Windows Phone') > 0) ||
  (navigator.userAgent.indexOf('Nokia') > 0)
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
} else {
  // PC時の処理
  uaFlag = 3;
}
/* -----------------------------------------------
IEだけの時
----------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}