const contactPage = $('#contactForm').length;

export function contactFunc() {

  if (contactPage) {
    
          /* ----------------------------------------------------------------------------------
    モーダルウィンドウ
    ---------------------------------------------------------------------------------- */
    var detailModalFunc = {
      init: function(onLoad) {
        if(onLoad) this._init();
      },
      _init: function() {
        // 設定
        var animateSpeed = 2000;
        var closeElem = '.close a';
        var btnCloseElem = '.closeBtn';
        var mainModalContent = '.detailModalContent';
        var myModalHeight;
        var pointScrollY;
        var currentScrollY;
  
        $(window).scroll(function() {
          pointScrollY = $(window).scrollTop();
        });
  
        // スライドされる高さの調整
         $(window).on('load resize', function() {
           var windowHeight = $(window).outerHeight();
           $(mainModalContent).find('.modalInner').css({'height':windowHeight}); //高さを出すスクロールを表示
         });
  
        $(mainModalContent).each(function(i) {
          i++;
          //console.log(i);
          $('.modal' + i).on('click touchstart', function() {
            // 背景固定
             currentScrollY = $(window).scrollTop();
            $('body').css({
              position: 'fixed',
              width: '100%',
              top: -1 * currentScrollY,
            });
  
            $('#modal' + i).fadeIn(animateSpeed);
  
            // 上下縦横中央寄せに実行
            var windowHeight = $(window).outerHeight(); // 縦方向の余白を保つ
            myModalHeight = $('#modal' + i).outerHeight();
            if(windowHeight <= myModalHeight) {
              myModalHeight = windowHeight;
              $(mainModalContent).css({'height':'100%'});
            }
            centeringModalSyncer(myModalHeight);
          });
  
          function closeAction() {
            // 背景を固定を解除
            $('body').attr({style:''});
            $('html,body').animate({scrollTop:currentScrollY},1);
            $(mainModalContent).stop().fadeOut(animateSpeed);
          }
  
          // クリックでモーダルをクローズ
          $(closeElem).on('click', function() {
            closeAction();
          });
        });
        $(window).on('resize', centeringModalSyncer);
  
        // 上下縦横中央寄せの関数
        function centeringModalSyncer(myModalHeight) {
          $(mainModalContent).css({
            'left': '0px',
            'top': '0px'
          });
        }
      }
    }
    detailModalFunc.init(true);


    const typeSelect = $("[name='type']");
    let selectIndex = '';

    const formField = $('#formField');
    // セレクタを使用して 'type' 属性が 'text' の input 要素を取得
    var textInputs = $("input[type='text']");
    var emailInputs = $("input[type='email']");
    var telInputs = $("input[type='tel']");
    var textareaInputs = $("textarea");

    function formResetFunc(){
      textInputs.val('');
      emailInputs.val('');
      telInputs.val('');
      textareaInputs.val('');
      $("input[type='checkbox']").prop('checked', false);
      $("input[type='radio']:not([name='type'])").prop('checked', false);
      $(".contactFormField .error").removeClass("error");
      $(".errorTxt").text("");
    }

    var radioButtons = $("input[name='type']");
    // 問い合わせ種別の選択時に入力データをリセットする
    radioButtons.on("click", function() {
      formResetFunc();
    });

    // お問い合わせ種別
    const selectControl = function () {
      for (var val = "", i = typeSelect.length; i--;) {
        if (typeSelect[i].checked) {
          var val = typeSelect[i].value;
          break;
        }
      }
      selectIndex = val;
      if (selectIndex != '') {
        if (selectIndex == 'online') {
          $('#online').addClass('isShow');
        } else {
          formField.addClass('isShow');
          if (selectIndex == 'business') {
            $('#business').addClass('isShow');
            $("#proposal").show();
            $('.business').addClass('isShow');
            $("#otherRequest").show();
            $("#proposalDetail").hide();
          } else if(selectIndex == 'proposal'){
            $('#business').addClass('isShow');
            $("#proposal").hide();
            $('.proposal').addClass('isShow');
            $("#proposalDetail").show();
            $("#otherRequest").hide();
          } else {
            $('#basic').addClass('isShow');
            if (selectIndex == 'product') {
              $('.product').addClass('isShow');
            } else if (selectIndex == 'inferior') {
              $('.inferior').addClass('isShow');
            } else if (selectIndex == 'interview') {
              $('.interview').addClass('isShow');
            } else if (selectIndex == 'other') {
              $('.other').addClass('isShow');
            }
          }
        }
      }
    }

    // 必須項目名
    const companyName = $("[name='companyName']");
    // const mediaName = $("[name='mediaName']");
    const contactName = $("[name='contactName']");
    const myName = $("[name='myName']");
    const email = $("[name='email']");
    const emailCheck = $("[name='emailCheck']");
    const subject = $("[name='subject']");
    const brand = $("[name='brand']");
    const product = $("[name='product']");
    const content = $("[name='content']");
    const otherRequest = $("[name='otherRequest']");
    const proposalDetail = $("[name='proposalDetail']");
    const agreeCheck = $("[name='agreeCheck']");

    const businessCompanyName = $("[name='businessCompanyName']");
    const representativeName = $("[name='representativeName']");
    const yearMonth = $("[name='yearMonth']");
    const capital = $("[name='capital']");
    const employeeNum = $("[name='employeeNum']");
    const tradingCompany = $("[name='tradingCompany']");
    const annualSales = $("[name='annualSales']");
    const companySite = $("[name='companySite']");
    const representTelNumber = $("[name='representTelNumber']");
    const businessContactName = $("[name='businessContactName']");
    const companyTelNumber = $("[name='companyTelNumber']");
    const companyAddress = $("[name='companyAddress']");
    const companyEmail = $("[name='companyEmail']");
    const companyEmailCheck = $("[name='companyEmailCheck']");
    const tradingPlans = $("[name='tradingPlans']");
    const tradingCountry = $("[name='tradingCountry']");
    const salesForms = $("[name='salesForms']");
    const salesChannel = $("[name='salesChannel']");
    const tradingProducts = $("[name='tradingProducts']");
    const monthlySales = $("[name='monthlySales']");

    // 必須項目定義
    const onlineRequired = [myName, email, emailCheck, subject, content, agreeCheck];
    const productRequired = [myName,email, emailCheck, brand, product, content, agreeCheck];
    const inferiorRequired = [myName, email, emailCheck, brand, product, content, agreeCheck];
    const interviewRequired = [companyName, contactName, email, emailCheck,  content, agreeCheck];
    const otherRequired = [myName, email, emailCheck, content, agreeCheck];
    const businessRequired = [businessCompanyName, representativeName, yearMonth, capital, employeeNum, tradingCompany, annualSales, companySite, representTelNumber, companyAddress, businessContactName, companyTelNumber, companyEmail, companyEmailCheck, salesChannel, monthlySales, agreeCheck];
    const proposalRequired = [businessCompanyName, representativeName, yearMonth, capital, employeeNum, tradingCompany, annualSales, companySite, representTelNumber, companyAddress, businessContactName, companyTelNumber, companyEmail, companyEmailCheck, agreeCheck];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      switch (selectIndex) {
        case 'online':
          requiredField = onlineRequired;
          break;
        case 'product':
          requiredField = productRequired;
          break;
        case 'inferior':
          requiredField = inferiorRequired;
          break;
        case 'business':
          requiredField = businessRequired;
          break;
        case 'proposal':
          requiredField = proposalRequired;
          break;
        case 'interview':
          requiredField = interviewRequired;
          break;
        case 'other':
          requiredField = otherRequired;
          break;
      }
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    selectControl();
    requiredFieldSet();

    // セレクト選択時実行
    typeSelect.change(function () {
      formField.removeClass('isShow');
      $('.split').removeClass('isShow');
      selectIndex = typeSelect.val();
      selectControl();
      requiredFieldSet();
    });

    const agreeCheckTxt = '「プライバシーポリシーに同意する」にチェックをしてください';
    const emailRequiredTxt = '正しいメールアドレスを入力してください';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります';
    const telRequiredTxt = '10桁〜12桁の数字を入力してください';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("※必須", "");;
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField == agreeCheck) {
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea').addClass('error').next('.errorTxt').text(agreeCheckTxt);
        } else {
          checkField.parents('.checkboxArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField.hasClass('select')) {
        errorTxt = checkFieldLabelTxt + 'は選択必須です';
        if (checkFieldValue == '') {
          checkField.addClass('error').parent('.selectBtn').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').parent('.selectBtn').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'tradingCountry') {
        errorTxt = '販売予定国は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'tradingPlans') {
        errorTxt = '希望取引は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'salesForms') {
        errorTxt = '販売形態は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'tradingProducts') {
        errorTxt = '取引希望製品は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'brand') {
        errorTxt = 'お問い合わせブランドは選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です';
        if (checkFieldName == 'content' && selectIndex != 'inferior') {
          errorTxt = 'お問い合わせ内容は入力必須です';
        }
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } else if (checkField == emailCheck && checkFieldValue != email.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        } else if (checkField == companyEmailCheck && checkFieldValue != companyEmail.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        } else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        } else if (checkFieldTag == 'TEXTAREA' && checkFieldValue.length >= 501) {
          errorTxt = '500文字以内で入力してください';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    otherRequest.blur(function () {
      if (otherRequest.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        otherRequest.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        otherRequest.removeClass('error').next('.errorTxt').text('');
      }
    });

    proposalDetail.blur(function () {
      if (proposalDetail.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        proposalDetail.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        proposalDetail.removeClass('error').next('.errorTxt').text('');
      }
    });

    $('.tradingCountry').on('change', function(){
        if (selectIndex == 'business') {
          var r = $('.tradingCountry:checked').length > 0;
          errorTxt = '販売予定国は選択必須です';

          if(r){
            $(this).parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $(this).parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }
        }
    });
    
    $('.tradingPlans').on('change', function(){
        if (selectIndex == 'business') {
          var r = $('.tradingPlans:checked').length > 0;
          errorTxt = '希望取引は選択必須です';

          if(r){
            $(this).parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $(this).parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }
        }
    });

    $('.salesForms').on('change', function(){
        if (selectIndex == 'business') {
          var r = $('.salesForms:checked').length > 0;
          errorTxt = '販売形態は選択必須です';

          if(r){
            $(this).parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $(this).parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }
        }
    });

    $('.tradingProducts').on('change', function(){
        if (selectIndex == 'business') {
          var r = $('.tradingProducts:checked').length > 0;
          errorTxt = '取引希望製品は選択必須です';

          if(r){
            $(this).parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $(this).parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }
        }
    });

    $('.attachedDocument').on('change', function(){
      //許可するタイプのリスト .jpg / .png / .pdf / .doc / .docx / .xls / .xlsx / .txt / .zip /.ppt / .pptx
      var acceptArray = new Array('image/jpeg','image/png','application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/plain','application/zip','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation');
      var formatArray = new RegExp('([^\s]+(\\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|txt|zip|ppt|pptx))$)', 'i');
      var limitSize = 10 * 1024 * 1024; //10MB

      const file = $(this).prop('files')[0];
      errorTxt = '添付資料はアップロードできない形式です';
      let errorTxt2 = '添付できるファイルの容量は10MBまでです';

      if(file){
        const fileName = $(this).prop('files')[0].name;
        const fileType = $(this).prop('files')[0].type;
        const fileSize = $(this).prop('files')[0].size;

        if(!fileName.match(formatArray)){
          $(this).parents('.attachedDocumentBox').addClass('error').next('.errorTxt').text(errorTxt);
          $(this).val('');
        }else if($.inArray(fileType, acceptArray) === -1){
          $(this).parents('.attachedDocumentBox').addClass('error').next('.errorTxt').text(errorTxt);
          $(this).val('');
        }else if(fileSize > limitSize){
          $(this).parents('.attachedDocumentBox').addClass('error').next('.errorTxt').text(errorTxt2);
          $(this).val('');
        }else{
          $(this).parents('.attachedDocumentBox').removeClass('error').next('.errorTxt').text('');
        }
      }
    });

    function is_checked(){
      if (selectIndex == 'business') {
          var cnt = 0;
          cnt += $('.tradingProducts:checked').length;
          errorTxt = '取引希望製品は選択必須です';
    
          if(cnt > 0){
            $('.tradingProducts').parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $('.tradingProducts').parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }

          var cnt = 0;
          cnt += $('.tradingCountry:checked').length;
          errorTxt = '販売予定国は選択必須です';
    
          if(cnt > 0){
            $('.tradingCountry').parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $('.tradingCountry').parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }

          var cnt = 0;
          cnt += $('.tradingPlans:checked').length;
          errorTxt = '希望取引は選択必須です';
    
          if(cnt > 0){
            $('.tradingPlans').parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $('.tradingPlans').parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }

          var cnt = 0;
          cnt += $('.salesForms:checked').length;
          errorTxt = '販売形態は選択必須です';
    
          if(cnt > 0){
            $('.salesForms').parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
          }else{
            $('.salesForms').parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
          }

          
          return cnt;
      }
    }
    
    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      is_checked();

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('#header').outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $(".checkboxArea input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

        // チェックボックスチェック時入力欄有効可
        const checkBtn02 = document.querySelectorAll('.checkBtn02');
        const checkField02 = document.querySelectorAll('.checkField02');
        for (let i = 0; i < checkBtn02.length; i++) {
          if (checkBtn02[i].checked) {
            checkField02[i].disabled = false;
          } else {
            checkField02[i].disabled = true;
            checkField02[i].value = '';
          }
        }
        $(".checkboxArea02 .checkBtn02").on('click', function () {
          for (let i = 0; i < checkBtn02.length; i++) {
            if (checkBtn02[i].checked) {
              checkField02[i].disabled = false;
            } else {
              checkField02[i].disabled = true;
              checkField02[i].value = '';
            }
          }
        });

            //添付ファイル
    document.querySelector(".attachedDocumentBtn").addEventListener("click", () => {
      document.querySelector(".attachedDocument").click();

      var selectedFiles = document.querySelector(".attachedDocument").files;
      if(selectedFiles.length == 0){
        $('.attachedDocumentTxt').removeClass('active').text('選択してください。');
        $('.attachedDocumentReset').val('0');
        $('.attachedDocument').val('');
      }
    });

    //ファイル名を表示する
    $('.attachedDocument').on('change', function(){
      let attachedDocumentName;
      let attachedDocumentFile = $(this).prop('files')[0];

      if(attachedDocumentFile && $(this)[0].files.length > 0){
        attachedDocumentName = $(this).prop('files')[0].name;
        if(attachedDocumentName){
          $('.attachedDocumentTxt').addClass('active').text(attachedDocumentName);
          $('.attachedDocumentReset').val('1');
        }
      } else{
        $('.attachedDocumentTxt').removeClass('active').text('選択してください。');
        $('.attachedDocumentReset').val('0');
      }
    });
  }
}

export function contactFaqFunc() {
  if (contactPage) {
    let getUrl = location.host;
    var post,api02;
    
    if(getUrl == 'iroha-brandsite-renewal.test'){
      post = {
        site_url: 'http://iroha-brandsite-renewal.test', //WPで記事を追加しているサイトURLを記述してください
      };
      api02 = post.site_url + "/wp-json/wp/v2/pages/3163";
    }else if(getUrl == 'localhost:3000'){
      post = {
        site_url: 'http://iroha-brandsite-renewal.test', //WPで記事を追加しているサイトURLを記述してください
      };
      api02 = post.site_url + "/wp-json/wp/v2/pages/3163";
    }else if(getUrl == 'iroha-tenga.com'){
      post = {
        site_url: 'https://iroha-tenga.com', //WPで記事を追加しているサイトURLを記述してください
      };
      api02 = post.site_url + "/wp-json/wp/v2/pages/5832";
    }else if(getUrl == 'dev-iroha.acceptee-tenga.com'){
      post = {
        site_url: 'http://dev-iroha.acceptee-tenga.com', //WPで記事を追加しているサイトURLを記述してください
      };
      api02 = post.site_url + "/wp-json/wp/v2/pages/5832";
    }else if(getUrl == 'dev.iroha-tenga.com'){
      post = {
        site_url: 'https://dev.iroha-tenga.com', //WPで記事を追加しているサイトURLを記述してください
      };
      api02 = post.site_url + "/wp-json/wp/v2/pages/5832";
    }

    //アイキャッチを取得したい場合は[&_embed]を追加する 
    var api = post.site_url + "/wp-json/wp/v2/faq/?per_page=100&?wpse_custom_order=menu_order&order=asc";
    //カテゴリ用
    var api_category = post.site_url + "/wp-json/wp/v2/faq_tax/?per_page=100&?wpse_custom_order=menu_order&order=asc";

    function processPostsByTermsName(results) {
      var tab = $('#faqListTab');
  
      results.forEach(function(result) {
        var termTitle = result.name;
        var termClass = result.slug;
        var termId = result.id;
        var count = result.count;
        var termHeader = $('<li>').addClass(termClass + ' tab').attr('data-id', termId);
        if(count != 0){
          tab.append(termHeader);
        }
        $("#faqListTab .tab").eq(0).addClass("active");
      });
    }
    $.getJSON( api_category, function(results) {
      processPostsByTermsName(results);
    });

    function processPostsByTerms(results) {
      var postsByTerms = {};
  
      results.forEach(function(result) {
          var terms = result.faq_tax;
  
          terms.forEach(function(term) {
              if (!postsByTerms[term]) {
                  postsByTerms[term] = [];
              }
              postsByTerms[term].push(result);
          });
      });
  
      // タームごとに分類された記事を表示
      displayPostsByTerms(postsByTerms);
    }

    function displayPostsByTerms(postsByTerms) {
      var container = $('#faqList');
      var originalArray = $("#faqListTab li").map(function(){
        return $(this).data('id');
      }).toArray();
      //console.log(originalArray);

      let sortedArray = [];
      // 元の配列の順番にオブジェクトを並べ替える
      originalArray.forEach(index => {
        sortedArray.push(postsByTerms[index]);
      });
  
      for (var termId in sortedArray) {
          if (sortedArray.hasOwnProperty(termId)) {
              var postDiv = $('<div class="faqListBox">');
              var postList = $('<ul class="faqList">');

              //console.log(sortedArray);
              //console.log(postsByTerms);
  
              sortedArray[termId].forEach(function(post) {
                  var postElement = $('<li class="animeLinkParent">');

                  var link = post.link;//記事のリンク
                  var faq_list_thumbnail = post.CUSTOM_REST_API_KEY[0][0];
                  var faq_list_thumbnail01 = post.CUSTOM_REST_API_KEY[1][0];
                  var faq_list_thumbnail02 = post.CUSTOM_REST_API_KEY[2][0];
                  var faq_list_alt = post.meta_field.faq_thumbnail_alt;
                  var faq_list_radio = post.meta_field.faq_tit_radio;
                  var faq_list_tit = post.acf.faq_tit;
                  let logo;

                  if(faq_list_radio == undefined || faq_list_radio == 'img'){
                    logo = `<img src="${faq_list_thumbnail01}" alt="${faq_list_alt}" class="pcOnly">`;
                  } else if(faq_list_radio == 'txt'){
                    logo = `<p class="logoTxt pcOnly">${faq_list_tit}</p>`;
                  }

                  postElement.html(`
                      <div class="img animeLinkEachImg"><a href="${link}"><img src ="${faq_list_thumbnail}" alt="" class="pcOnly">${logo}<img src="${faq_list_thumbnail02}" alt="${faq_list_alt}" class="spOnly"></a></div>
                  `);
  
                  postList.append(postElement);
              });

              postDiv.append(postList);
              container.append(postDiv);
              $("#faqList .faqListBox").eq(0).addClass("active");
          }
      }
    }

    $.getJSON( api, function(results) {

      processPostsByTerms(results);

      // $.each(results, function(i, item) {
      //   //各項目を変数へ格納 
      //   var link = item.link;//記事のリンク

      //   var faq_list_thumbnail = item.CUSTOM_REST_API_KEY[0][0];
      //   var faq_list_thumbnail01 = item.CUSTOM_REST_API_KEY[1][0];
      //   var faq_list_thumbnail02 = item.CUSTOM_REST_API_KEY[2][0];
      //   var faq_list_thumbnail02 = item.CUSTOM_REST_API_KEY[2][0];
      //   var faq_list_alt = item.meta_field.faq_thumbnail_alt;
      //   var faq_list_tax = item.faq_tax[0];
         
      //   //指定のクラスへ投稿を追加
      //   $('#faqList').append("<li class='animeLinkParent'><div class='img animeLinkEachImg'><a href='" + link + "'><img src ='" + faq_list_thumbnail + "' alt='' class='pcOnly'><img src='" + faq_list_thumbnail01 + "' alt='" + faq_list_alt +"' class='pcOnly'><img src='" + faq_list_thumbnail02 + "' alt='" + faq_list_alt +"' class='spOnly'></a></div>'" + faq_list_tax +"' </li>");
      // });
    });

    //アイキャッチを取得したい場合は[&_embed]を追加する 
    $.getJSON( api02, function(results02) {

      for (let i = 0; i < results02.acf.lot_num_repeat.length; i++) {
        var title = results02.acf.lot_num_repeat[i].lot_num_products;
        var content = results02.acf.lot_num_repeat[i].lot_num_con;
        $('#modalDateList').append("<dt>" + title + "</dt><dd>" + content + "</dd>");
      }
    });
  }
}